<template>
<v-app-bar absolute dark elevation="10" extension-height="64" color="white" height="auto">
      <v-container class="fill-height">
        <!-- <img  :height="this.$vuetify.breakpoint.xs ? 50 : 70" class="image-logo2" src="@/assets/img/Quantum Disputes Logo.png" /> -->
        <img  :height="this.$vuetify.breakpoint.xs ? 50 : 50" class="image-logo2" src="@/assets/img/QUANTUM FRSOLUTIONS TP.png" />
        <!-- <h1 class="" style="color:#5DB461;">
            QUANTUM DISPUTE
          </h1> -->
      </v-container>
      <template #extension>
        <v-toolbar color="secondary" dark>
          <v-container>
            <v-row>
              <v-col cols="3"></v-col>
            <v-col cols="6" class="mx-auto text-center ">
          <template v-if="!$vuetify.breakpoint.mobile">
            <v-menu
              v-for="item in links"
              :key="item.name"
              :open-on-hover="!item.single"
              offset-y
              bottom
              min-width="360"
              max-width="600"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="item.name != 'Profile'"
                  v-bind="attrs"
                  v-on="on"
                  :ripple="false"
                  color="transparent"
                  :class="$route.path == item.link ? 'active-button' : 'text-white'"
                  class="
                    
                    font-weight
                    text-capitalize
                    ls-0
                    align-center
                  "
                  depressed
                  link
                  :to="item.link ? item.link : '#'"
                >
                  {{ item.name }}
                  <v-img
                  v-if="!item.single"
                    src="@/assets/img/down-arrow-white.svg"
                    alt="down-arrow"
                    class="arrow ms-1 d-lg-block d-none"
                  />
                </v-btn>
                  <v-btn
                  v-else
                  v-bind="attrs"
                  v-on="on"
                  :ripple="false"
                  color="transparent"
                  :class="$route.path == item.link ? 'active-button' : 'text-white'"
                  class="
                    
                    font-weight
                    text-capitalize
                    ls-0
                    align-center
                  "
                  depressed
                  link
                >
                  {{ item.name }}
                  <v-img
                  v-if="!item.single"
                    src="@/assets/img/down-arrow-white.svg"
                    alt="down-arrow"
                    class="arrow ms-1 d-lg-block d-none"
                  />
                </v-btn>
              </template>

              <v-list class="border-radius-xl overflow-hidden">
                <template v-if="item.name == 'Pages'">
                  <app-bar-pages></app-bar-pages>
                </template>
                <template v-if="item.name == 'Authentication'">
                  <app-bar-authentication></app-bar-authentication>
                </template>
                <template v-if="item.name == 'Applications'">
                  <app-bar-applications></app-bar-applications>
                </template>
                <template v-if="item.name == 'Ecommerce'">
                  <app-bar-ecommerce></app-bar-ecommerce>
                </template>
                <template v-if="item.name == 'Profile'">
                      <v-list>
                        <v-list-item>
                          <!-- <v-list-item-avatar> -->
                            <!-- <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img> -->
                             <v-avatar size="100" class="my-3 ms-2">
                            <img v-if="!avatar" src="@/assets/img/avatar.png" alt="Brooklyn" />
                            <img v-else :src="avatar" alt="Brooklyn" />
                          </v-avatar>
                          <!-- </v-list-item-avatar> -->
                        </v-list-item>

                        <v-list-item link>
                          <v-list-item-content>
                            <v-list-item-title class="text-h3">
                              {{name}}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{email}}</v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-icon>mdi-menu-down</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <v-divider></v-divider>
                  <v-list class="px-3">
                   
                      <v-list-item v-for="item_child in item.pages" :key="item_child.title" class="mb-0 ps-0">
                        <v-btn
                          :ripple="false"
                          text
                          v-if="item_child.title != 'Logout'"
                          class="text-dark font-weight-normal text-capitalize ls-0"
                          depressed
                          link
                          :to="item_child.link"
                        >
                          <v-icon v-if="item_child.title != 'Logout'" size="16" class="material-icons-round text-dark opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <v-icon v-if="item_child.title == 'Logout'" size="16" class="material-icons-round active-button opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <span :class="item_child.title == 'Logout'  ? 'active-button' : ''">{{ item_child.title }}</span>
                          <v-spacer></v-spacer>
                        </v-btn>
                        <v-btn
                          :ripple="false"
                          text
                          v-if="item_child.title == 'Logout'"
                          class="text-dark font-weight-normal text-capitalize ls-0"
                          depressed
                          link
                          @click="logout()"
                        >
                          <v-icon v-if="item_child.title != 'Logout'" size="16" class="material-icons-round text-dark opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <v-icon v-if="item_child.title == 'Logout'" size="16" class="material-icons-round active-button opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <span :class="item_child.title == 'Logout'  ? 'active-button' : ''">{{ item_child.title }}</span>
                          <v-spacer></v-spacer>
                        </v-btn>
                      </v-list-item>
                    </v-list>
                </template>
                <template v-if="item.name == 'Custom Links'">
                  <v-list class="px-3">
                   
                      <v-list-item v-for="item_child in item.pages" :key="item_child.title" class="mb-0 ps-0">
                        <v-btn
                          :ripple="false"
                          text
                          v-if="item_child.is_iframe"
                          class="text-dark font-weight-normal text-capitalize ls-0"
                          depressed
                          link
                          :to="item_child.link"
                        >
                          <v-icon v-if="item_child.title != 'Logout'" size="16" class="material-icons-round text-dark opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <v-icon v-if="item_child.title == 'Logout'" size="16" class="material-icons-round active-button opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <span :class="item_child.title == 'Logout'  ? 'active-button' : ''">{{ item_child.title }}</span>
                          <v-spacer></v-spacer>
                        </v-btn>
                        <v-btn
                          :ripple="false"
                          text
                          v-else
                          class="text-dark font-weight-normal text-capitalize ls-0"
                          depressed
                          link
                          @click="gotoLink(item_child.link_url)"
                        >
                          <v-icon v-if="item_child.title != 'Logout'" size="16" class="material-icons-round text-dark opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <v-icon v-if="item_child.title == 'Logout'" size="16" class="material-icons-round active-button opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <span :class="item_child.title == 'Logout'  ? 'active-button' : ''">{{ item_child.title }} </span>
                          <v-spacer></v-spacer>
                        </v-btn>
                        <v-btn
                          :ripple="false"
                          text
                          v-if="item_child.title == 'Logout'"
                          class="text-dark font-weight-normal text-capitalize ls-0"
                          depressed
                          link
                          @click="logout()"
                        >
                          <v-icon v-if="item_child.title != 'Logout'" size="16" class="material-icons-round text-dark opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <v-icon v-if="item_child.title == 'Logout'" size="16" class="material-icons-round active-button opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <span :class="item_child.title == 'Logout'  ? 'active-button' : ''">{{ item_child.title }}</span>
                          <v-spacer></v-spacer>
                        </v-btn>
                      </v-list-item>
                    </v-list>
                </template>
              </v-list>
            </v-menu>
          </template>
        </v-col>
         <v-col cols="3" class="text-right align-center">
          
          <template v-if="!$vuetify.breakpoint.mobile && !company_id">
            
            <v-btn
              elevation="0"
              color="#FF5353"
              @click="$router.push('/pricing')"
              class="font-weight-bolder py-4 px-4 ms-2"
              :class="'btn-primary '
              "
              href="javascript:;"
            >
              Buy Credit
            </v-btn>
          </template>
          <v-btn
              elevation="0"
              color="blue"
              v-if="from_admin"
              @click="loginAdmin"
              class="font-weight-bolder py-4 px-4 ms-2"
              :class="'btn-primary '
              "
              href="javascript:;"
            >
              Back to admin
            </v-btn>
          <v-btn
            v-show="$vuetify.breakpoint.mobile"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-600
              text-capitalize
              drawer-toggler
              btn-toggler-hover
              py-3
              px-6
              rounded-sm
            "
            color="white"
            @click="dialog = true"
          >
            <div class="drawer-toggler-inner">
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
            </div>
          </v-btn>

          <v-dialog
            v-model="dialog"
            content-class="position-absolute top-0"
            width="95%"
          >
            <v-card class="card-shadow card-padding">
              <v-card-title
                class="
                  pt-0
                  px-4
                  card-padding
                  text-h6
                  font-weight-bold
                  text-typo
                  justify-space-between
                  border-bottom
                "
              >
                QUANTUM DISPUTE

                <v-btn icon width="31" :ripple="false" @click="dialog = false">
                  <v-icon size="18" class="text-typo">fas fa-times</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="card-padding pb-0 d-flex flex-column px-0">
                <v-btn
                  v-for="item in links"
                  :key="item.name"
                  :ripple="false"
                  text
                  class="text-typo text-capitalize ls-0"
                  depressed
                  link
                  :to="item.link"
                >
                  {{ item.name }}
                  <v-spacer></v-spacer>
                </v-btn>

                <div class="border-bottom my-7"></div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
            </v-row>
          </v-container>
        </v-toolbar>
      </template>
    </v-app-bar>
</template>
<script>
// import AppBarPages from "./Widgets/AppBarPages.vue";
// // import AppBarAuthentication from "./Widgets/AppBarAuthentication.vue";
// // import AppBarApplications from "./Widgets/AppBarApplications.vue";
// import AppBarEcommerce from "./Widgets/AppBarEcommerce.vue";
// import AppBarDocs from "./Widgets/AppBarDocs.vue";

export default {
  name: "app-bar",
  components: {
    // AppBarPages,
    // AppBarAuthentication,
    // // AppBarApplications,
    // AppBarEcommerce,
    // AppBarDocs,
  },
  props: {
    background: String,
    hasBg: true,
    linkColor: String,
  },
  created () {
    console.log(this.$route.path);
    this.getLinks();
  },
  methods: {
    loginAdmin(){
      if(confirm('Are you sure, You want to login as a admin?')){
            axios({
              method: "POST",
              
              url: "/api/auth/login-admin-client-admin",
              data: {
                email: this.from_admin_email            }
            })
              .then(response => {
                let data = response.data;
                if (data.flag == 1) {
                  let result = data.data;
                  localStorage.setItem("token", data.token.token);
                  localStorage.setItem("user_id", result.user_id);
                  localStorage.setItem("first_name", result.first_name);
                  localStorage.setItem("email", result.email);
                  localStorage.setItem("is_admin", result.is_admin);
                  localStorage.setItem("name", result.first_name+' '+result.last_name);
                  localStorage.setItem("role", JSON.stringify(result.roles[0]));
                  localStorage.setItem("company_name", result.company_name);
                  localStorage.setItem("no_tiptext_flag", '');
                  localStorage.setItem('company_logo',process.env.VUE_APP_WASBI_URL+result.logo);
                  localStorage.setItem("company_id", result.company_id);
                  this.$router.push("/admin/dashboard");
                  location.reload();
                } else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }
              })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
              });
          }
    },
    getLinks(){
          let headers = {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
          axios
            .get("/api/get-all-link", {headers: headers})
            .then(response => {
              this.custom_links = response.data.data
              this.custom_links.forEach(val =>{
                if(val.client)
                  this.links[4].pages.push({
                      title: val.title,
                      icon: val.icon,
                      link: "/custom-link/"+val.id,
                      link_url  : val.url,
                      is_iframe : val.is_iframe
                    })
              })
              
            })
        },
        gotoLink(item){
          window.open(item, "_blank");
        },
    logout() {
      let self = this;
        let headers = {
            Authorization: "Bearer " + localStorage.getItem('token')
        }
      axios
        .get("/api/logout", {headers: headers})
        .then(response => {
            localStorage.removeItem("token")
            localStorage.removeItem("user_id");
            localStorage.removeItem("email");
            localStorage.removeItem("name");
            if(self.company.company_url)
              this.$router.push("/login/"+self.company.company_url);
            else
              this.$router.push("/");
            window.location.reload();
        })
        .catch(error => {
            localStorage.removeItem("token")
            localStorage.removeItem("user_id");
            localStorage.removeItem("email");
            localStorage.removeItem("name");
            this.$router.push("/");
            location.reload();
        });
    },
  },
  data() {
    return {
      dialog: false,
      plan : localStorage.getItem('plan') ,
      company : '',
      custom_links: [],
      name : localStorage.getItem('name'),
      user_id: localStorage.getItem('user_id'),
      email: localStorage.getItem('email'),
      avatar : localStorage.getItem('avatar') ? localStorage.getItem('avatar') : '',
      loadingLogo : false,
      from_admin : localStorage.getItem('from_admin') ? localStorage.getItem('from_admin') : 0,
      from_admin_email : localStorage.getItem('from_admin_email') ? localStorage.getItem('from_admin_email') : '',
      logo  : localStorage.getItem('company_logo'),
      company_id : localStorage.getItem('company_id') == "null" ? '' : localStorage.getItem('company_id'),
      doc_flag : localStorage.getItem('agreement_flag'),
      links: [
        {
          name: "Dashboard",
          single: true,
          link: "/dashboard",
        },
        {
          name: "My Requests",
          link: "/my-request",
          single: true
        },
        {
          name: "Billing",
          link: "/credit-history",
          single: true
        },
        {
          name: "Profile",
          link: "/pages/pages/register",
          single: false,
          pages: [
                  {
                    title: "Edit Profile",
                    icon: "fa-user",
                    link: "/profile",
                  },
                  {
                    title: "Change Password",
                    icon: "fa-lock",
                    link: "/change-password",
                  },
                  {
                    title: "Logout",
                    icon: "fa-power-off",
                    link: "/pages/applications/wizard",
                  },
                 
                ],
        },
        {
          name: "Custom Links",
          link: "",
          single: false,
          pages: [
                  
                 
                ],
        },
       
      ],
    };
  },
};
</script>
<style>
.v-toolbar__content, .v-toolbar__extension {
  padding: 0;
}
.btn-dark-hover:hover, .btn-dark-hover:focus {
  color: #53AD57 !important;
}
.active-button{
  color : #FF5353 !important;
}
</style>