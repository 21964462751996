<template>
  <v-navigation-drawer
    width="100%"
    height="calc(100% - 2rem)"
    fixed
    app
    floating
    :expand-on-hover="mini"
    :value="drawer"
    :right="$vuetify.rtl"
    class="my-4 ms-4 border-radius-xl"
    :class="!$vuetify.breakpoint.mobile ? '' : 'bg-white'"
    :data-color="sidebarColor"
    :data-theme="sidebarTheme"
  >
    <!-- <v-list-item >
      <v-list-item-content >
        <v-toolbar-title class=" font-weight-black" style="color:white;margin-top: 50px;font-size: 1.50rem;margin-left:10px">
            <img :height="this.$vuetify.breakpoint.xs ? 150 : 100" class="image-logo2" src="@/assets/img/Quantum Disputes.png">
          </v-toolbar-title>
      </v-list-item-content>
    </v-list-item> -->
        <v-list-item>
        <v-list-item-content style="margin-top:50px;">
         <img v-if="role.name == 'super_admin'" :height="this.$vuetify.breakpoint.xs ? 150 : 100" class="image-logo2 mt-5" src="@/assets/img/QUANTUM FRSOLUTIONS TP.png" style="height: 100%;
width: 1px;">

        <img v-else :height="this.$vuetify.breakpoint.xs ? 150 : 100" class="image-logo2 mt-3" :src="company_logo" style="height: 100%;
width: 1px;"> 
        </v-list-item-content>
      </v-list-item>

    <hr
      class="horizontal"
      style="margin-top:50px;"
      :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
    />

    <v-list nav dense>
      <v-list-group
        :ripple="false"
        v-model="userInfo_active"
        append-icon="fas fa-angle-down"
        class="pb-1 mx-2"
        active-class="item-active"
      >
        <template v-slot:activator>
          <v-avatar size="30" class="my-3 ms-2">
            <img v-if="!avatar" src="@/assets/img/avatar.png" alt="Brooklyn" />
            <img v-else :src="avatar" alt="Brooklyn" />
          </v-avatar>

          <v-list-item-content>
            <v-list-item-title class="ms-2 ps-1 font-weight-light">
              {{name}}
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          :ripple="false"
          link
          class="mb-1 no-default-hover px-0"
          :class="'has-children'"
          v-if="role.name == 'super_admin'"
          v-for=" child in userInfo"
          :key="child.title"
          @click="child.title == 'Logout' ? logout() : ''"
          :to="child.link"
        >
          <div class="w-100 d-flex align-center pa-2 border-radius-lg">
            <span class="v-list-item-mini" v-text="child.prefix"></span>

          <v-list-item-content class="ms-2 ps-1" v-if="!child.items">
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
          </div>
          <v-list-item-content class="ms-1 ps-1 py-0" v-if="child.items">
            <v-list-group
              prepend-icon=""
              :ripple="false"
              sub-group
              no-action
              v-model="child.active"
            >
              <template v-slot:activator>
                <v-list nav dense class="pa-0">
                  <v-list-group
                    :ripple="false"
                    append-icon="fas fa-angle-down me-auto ms-1"
                    active-class="item-active"
                    class="mb-0"
                  >
                    <template v-slot:activator class="mb-0">
                      <v-list-item-content class="py-0">
                        <v-list-item-title
                          v-text="child.title"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-group>
                </v-list>
              </template>

              <v-list-item
                v-for="child2 in child.items"
                :ripple="false"
                :key="child2.title"
                :to="child2.link"
                @click="listClose($event)"
              >
                <span class="v-list-item-mini" v-text="child2.prefix"></span>
                <v-list-item-content>
                  <v-list-item-title v-text="child2.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :ripple="false"
          link
          class="mb-1 no-default-hover px-0"
          :class="'has-children'"
          v-if="role.name == 'company_admin'"
          v-for=" child in userInfoCompany"
          :key="child.title"
          @click="child.title == 'Logout' ? logout() : ''"
          :to="child.link"
        >
          <div class="w-100 d-flex align-center pa-2 border-radius-lg">
            <span class="v-list-item-mini" v-text="child.prefix"></span>

          <v-list-item-content class="ms-2 ps-1" v-if="!child.items">
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
          </div>
          <v-list-item-content class="ms-1 ps-1 py-0" v-if="child.items">
            <v-list-group
              prepend-icon=""
              :ripple="false"
              sub-group
              no-action
              v-model="child.active"
            >
              <template v-slot:activator>
                <v-list nav dense class="pa-0">
                  <v-list-group
                    :ripple="false"
                    append-icon="fas fa-angle-down me-auto ms-1"
                    active-class="item-active"
                    class="mb-0"
                  >
                    <template v-slot:activator class="mb-0">
                      <v-list-item-content class="py-0">
                        <v-list-item-title
                          v-text="child.title"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-group>
                </v-list>
              </template>

              <v-list-item
                v-for="child2 in child.items"
                :ripple="false"
                :key="child2.title"
                :to="child2.link"
                @click="listClose($event)"
              >
                <span class="v-list-item-mini" v-text="child2.prefix"></span>
                <v-list-item-content>
                  <v-list-item-title v-text="child2.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>

    <hr
      class="horizontal"
      style="margin-top:11px;"
      :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
    />

    <v-list nav dense>

      <v-list-item-group>
        <div >
          <v-list-item
            link
            v-model="dashboard_active"
            :to="'/admin/dashboard'"
            class="pb-1 mx-2 no-default-hover py-2"
            :ripple="false"
            active-class="item-active"
          >
            <v-list-item-icon class="me-2 align-center">
              <i class="material-icons-round opacity-10">dashboard</i>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Dashboard'"
                class="ms-1"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          
        </div>
      </v-list-item-group>
      
      <v-list-item-group>
        <div >
          <v-list-item
            link
            v-model="dashboard_active"
            :to="'/admin/grant-dashboard'"
            class="pb-1 mx-2 no-default-hover py-2"
            :ripple="false"
            active-class="item-active"
          >
            <v-list-item-icon class="me-2 align-center">
              <i class="material-icons-round opacity-10">price_check</i>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Grant Dashboard'"
                class="ms-1"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          
        </div>
      </v-list-item-group>


      <v-list-group
        :ripple="false"
        v-for="item in itemsPages"
        :key="item.title"
        v-if="role.name == 'super_admin'"
        v-model="item.active"
        append-icon="fas fa-angle-down"
        class="pb-1 mx-2"
        active-class="item-active"
      >
        <template v-slot:activator>
          <v-list-item-icon class="me-2 align-center">
            <i class="material-icons-round opacity-10">{{ item.action }}</i>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-text="item.title"
              class="ms-1"
            ></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          :ripple="false"
          link
          class="mb-1 no-default-hover px-0"
          :class="child.items ? 'has-children' : ''"
          v-for="child in item.items"
          :key="child.title"
          @click="item.title == 'Custom Links' ? gotolink(child.id,child) : false"
          :to="item.title == 'Custom Links' ? '' : child.link"
        >
            <div class="w-100 d-flex align-center pa-2 border-radius-lg">
            <span class="v-list-item-mini" v-text="child.prefix"></span>

          <v-list-item-content class="ms-2 ps-1" v-if="!child.items">
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
          </div>

          <v-list-item-content class="py-0" v-if="child.items">
            <v-list-group
              prepend-icon=""
              :ripple="false"
              sub-group
              no-action
              v-model="child.active"
            >
              <template v-slot:activator>
                <v-list nav dense class="py-2 ps-5 pe-2">
                  <v-list-group
                    :ripple="false"
                    append-icon="fas fa-angle-down me-auto ms-1"
                    active-class="item-active"
                    class="mb-0"
                  >
                    <template v-slot:activator class="mb-0">
                      <div class="w-100 d-flex align-center">
                        <span
                          class="v-list-item-mini ms-1"
                          v-text="child.prefix"
                        ></span>

                        <v-list-item-content class="py-0 ms-4">
                          <v-list-item-title
                            class="ms-2"
                            v-text="child.title"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </div>
                    </template>
                  </v-list-group>
                </v-list>
              </template>

            </v-list-group>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
 <v-list-group
        :ripple="false"
        v-for="item in itemsPagesCompany"
        :key="item.title"
        v-if="role.name == 'company_admin'"
        v-model="item.active"
        append-icon="fas fa-angle-down"
        class="pb-1 mx-2"
        active-class="item-active"
      >
        <template v-slot:activator>
          <v-list-item-icon class="me-2 align-center">
            <i class="material-icons-round opacity-10">{{ item.action }}</i>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-text="item.title"
              class="ms-1"
            ></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          :ripple="false"
          link
          class="mb-1 no-default-hover px-0"
          :class="child.items ? 'has-children' : ''"
          v-for="child in item.items"
          :key="child.title"
          @click="item.title == 'Custom Links' ? gotolink(child.id,child) : false"
          :to="item.title == 'Custom Links' ? '' : child.link"
        >
            <div class="w-100 d-flex align-center pa-2 border-radius-lg">
            <span class="v-list-item-mini" v-text="child.prefix"></span>

          <v-list-item-content class="ms-2 ps-1" v-if="!child.items">
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
          </div>

          <v-list-item-content class="py-0" v-if="child.items">
            <v-list-group
              prepend-icon=""
              :ripple="false"
              sub-group
              no-action
              v-model="child.active"
            >
              <template v-slot:activator>
                <v-list nav dense class="py-2 ps-5 pe-2">
                  <v-list-group
                    :ripple="false"
                    append-icon="fas fa-angle-down me-auto ms-1"
                    active-class="item-active"
                    class="mb-0"
                  >
                    <template v-slot:activator class="mb-0">
                      <div class="w-100 d-flex align-center">
                        <span
                          class="v-list-item-mini ms-1"
                          v-text="child.prefix"
                        ></span>

                        <v-list-item-content class="py-0 ms-4">
                          <v-list-item-title
                            class="ms-2"
                            v-text="child.title"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </div>
                    </template>
                  </v-list-group>
                </v-list>
              </template>

            </v-list-group>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      
    </v-list>

    <v-card
      class="pa-0 border-radius-lg mt-7 mb-9 mx-4"
      :style="`background-image: url(${require('../assets/img/curved-images/white-curved.jpeg')}); background-size: cover;`"
    >
      <span
        class="mask opacity-8 border-radius-lg"
        :class="`bg-gradient-` + sidebarColor"
      ></span>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
import { isValidElement } from 'preact';

export default {
  name: "drawer",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: "success",
    },
    sidebarTheme: {
      type: String,
      default: "dark",
    },
  },
  created () {
        let self = this;
        let token = localStorage.getItem('token');
        let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        this.getLinks();
        this.menuActive()

  },
  data: () => ({
    mini: false,
    togglerActive: false,
    userInfo_active : false,
    dashboard_active : false,
    company_name : localStorage.getItem('company_name'),
    company_id: localStorage.getItem('company_id'),
    plan : localStorage.getItem('plan') ,
    name : localStorage.getItem('name'),
    email : localStorage.getItem('email'),
    avatar : localStorage.getItem('avatar') ? localStorage.getItem('avatar') : '',
    company_logo : localStorage.getItem('company_logo'),
    credit : localStorage.getItem('credit') ? localStorage.getItem('credit') : 0,
    userDetails :[],
    links : [],
    role: JSON.parse(localStorage.getItem('role')),
    thirdLevelSimple: [
      "Third level menu",
      "Just another link",
      "One last link",
    ],
    userInfo: [
      {
        title: "My Profile",
        link : '/admin/my-profile'
      },
      {
        title: "Settings",
        link : '/admin/settings'
      },
      {
        title: "Subscription Settings",
        link : '/admin/subscription-settings'
      },
      {
        title: "Payment Gateway",
        link : '/admin/payment-gateway'
      },
      {
        title: "Logout",
        link : '#'
      },
    ],
    userInfoCompany: [
      {
        title: "My Profile",
        link : '/admin/my-profile'
      },
      {
        title: "Manage Subscription",
        link : '/admin/manage-subscription'
      },
      {
        title: "Credit History",
        link : '/admin/credit-history'
      },
      {
        title: "Logout",
        link : '#'
      },
    ],

   
    itemsPages: [
      {
        action: "domain",
        active: false,
        title: "Company",
        items: [
          {
            title: "Company Profiles",
            link: "/admin/company-profiles",
          },
          {
            title: "Add New Company",
            link: "/admin/add-company",
          },
          
        ],
      },
      {
        action: "groups",
        active: false,
        title: "Client",
        items: [
          {
            title: "Client Profiles",
            link: "/admin/client-profiles",
          },
          {
            title: "Add New Client",
            link: "/admin/add-client",
          },
          {
            title: "Requests",
            link: "/admin/requests",
          },
          {
            title: "Certified Letters",
            link: "/admin/certified-letters",
          },
          
        ],
      },
      {
        action: "email",
        active: false,
        title: "Mailing",
        items: [
          {
            title: "Inbox",
            link: "/admin/mailing",
          },
          {
            title: "Cart",
            link: "/admin/cart",
          },
          
        ],
      },
      {
        action: "fax",
        active: false,
        title: "Faxes",
        items: [
          {
            title: "Sent Faxes",
            link: "/admin/fax",
          },
          {
            title: "Queued Faxes",
            link: "/admin/queued-fax",
          },
          
        ],
      },
      {
        action: "share",
        active: false,
        title: "Affiliates",
        items: [
          {
            title: "Affiliate Links",
            link: "/admin/affiliates",
          },
          
          
        ],
      },
       {
        action: "public",
        active: false,
        title: "Custom Links",
        items: [
        
        ],
      },
     
    ],
    itemsPagesCompany: [
      {
        action: "domain",
        active: false,
        title: "Company",
        items: [
          {
            title: "Company Users",
            link: "/admin/company-users",
          },
          {
            title: "Company Settings",
            link : '/admin/company-settings'
          },
          
        ],
      },
      {
        action: "groups",
        active: false,
        title: "Client",
        items: [
          {
            title: "Client Profiles",
            link: "/admin/client-profiles",
          },
          {
            title: "Add New Client",
            link: "/admin/add-client",
          },
          {
            title: "Requests",
            link: "/admin/requests",
          },
          {
            title: "Certified Letters",
            link: "/admin/certified-letters",
          },
          
        ],
      },
       {
        action: "public",
        active: false,
        title: "Custom Links",
        items: [
        
        ],
      },
     
    ],
  }),
  methods: {
    menuActive(){
      let self = this;
      if(this.$route.path=='/admin/my-profile' || this.$route.path=='/admin/settings' || this.$route.path=='/admin/payment-gateway' || this.$route.path=='/admin/credit-history' || this.$route.path=='/admin/manage-subscription' ){
        this.userInfo_active = true;
      }else if(this.$route.path=='/admin/dashboard'){
        this.dashboard_active = true;
      }else{
        if(this.role.name =='super_admin'){
          this.itemsPages.forEach(val => {
            val.items.forEach(val2 => {
              if(val2.link == self.$route.path){
                val.active = true;
              }
            })
          })
        }else{
          this.itemsPagesCompany.forEach(val => {
            val.items.forEach(val2 => {
              if(val2.link == self.$route.path){
                val.active = true;
              }
            })
          })
        }
      }
    },
    logout() {
        let headers = {
            Authorization: "Bearer " + localStorage.getItem('token')
        }
      axios
        .get("/api/logout", {headers: headers})
        .then(response => {
            localStorage.removeItem("token")
            localStorage.removeItem("user_id");
            localStorage.removeItem("email");
            localStorage.removeItem("name");
            localStorage.removeItem("is_admin");
            this.$router.push("/admin");
            window.location.reload();
        })
        .catch(error => {
            localStorage.removeItem("token")
            localStorage.removeItem("user_id");
            localStorage.removeItem("email");
            localStorage.removeItem("name");
            this.$router.push("/admin");
            location.reload();
        });
    },
    getLinks(){
      let headers = {
            Authorization: "Bearer " + localStorage.getItem('token')
        }
      axios
        .get("/api/get-all-link", {headers: headers})
        .then(response => {
           console.log(response);
           this.links = response.data.data
           this.links.forEach(val =>{
            if(this.role.name =='super_admin'){
              this.itemsPages[this.itemsPages.length - 1].items.push({
                  title: val.title,
                  link: val.url,
                  id : val.id,
                  is_iframe : val.is_iframe
                });
            }
            else{
              this.itemsPagesCompany[this.itemsPagesCompany.length - 1].items.push({
                  title: val.title,
                  link: val.url,
                  id : val.id,
                  is_iframe : val.is_iframe
                });
            }
           })
           
        })
    },
    gotoProfile: function () {
        this.$router.push({ name: "Profile" });
      },
      gotolink(id,item){
        if(item.is_iframe == 1)
          this.$router.push('/admin/custom-link/'+id)
        else
          window.open(item.link, "_blank");
        
      },
    gotodashboard(id){

    this.$router.push('/admin/'+id)

    },
    gotomail(){

    this.$router.push('/admin/mailing')

    },
    gotocart(){

    this.$router.push('/admin/cart')

    },
    gotofax(){

    this.$router.push('/admin/fax')

    },
    gotoqfax(){

    this.$router.push('/admin/queued-fax')

    },
    gotoaffi(){

    this.$router.push('/admin/affiliates')

    },
    
    gotosettings(){
      this.$router.push('/admin/settings')
  },gotoremove(){
      this.$router.push('/remove-info')
    },
    checkLink(item){
      console.log(item);
      if(item.sidebar == 1){
        
        if(this.role.name == 'super_admin' || item.all_account == 1){
          return true;
        }else{
          console.log(item.list_account);
          let arr = JSON.parse(item.list_account);
          console.log(arr);
          if(arr.includes(this.company_id))
            return true;
          else
            return false;
        }
      }
      return false;
    },
    selectSection(index) {
        // this.selectedSection = item;
        if(index==0){
           console.log('EMAIL');
        }
        if(index==1){
          this.$router.push('/profile');
        }
        if(index==2){
          this.logout();
        }

    },

  },
    listClose(event) {
      let items;
      let headers;
      let groups;
      let currentEl;

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        )
      ) {
        items = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        )
      ) {
        headers = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        )
      ) {
        groups = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        );
      }

      if (
        event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        )
      ) {
        currentEl = event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        );
      }

      if (items != null) {
        for (var i = 0; i < items.length; i++) {
          items[i].classList.remove("item-active");
        }
      }

      if (headers != null) {
        for (var j = 0; j < headers.length; j++) {
          headers[j].classList.remove(
            "v-list-item--active",
            "item-active",
            "primary--text"
          );
          headers[j].setAttribute("aria-expanded", false);
        }
      }

      if (groups != null) {
        for (var k = 0; k < groups.length; k++) {
          groups[k].classList.remove("v-list-group--active", "primary--text");
        }
      }

      if (event.target.closest(".mb-0.v-list-item.v-list-item--link")) {
        event.target
          .closest(".mb-0.v-list-item.v-list-item--link")
          .classList.add("item-active");
      }

      if (currentEl != null) {
        currentEl
          .querySelector(".v-list-group__header")
          .classList.add("v-list-item--active", "item-active");
      }
  },
};
</script>
